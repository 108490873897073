import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Carousel from 'react-material-ui-carousel';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import content2 from './local_payload/aboutPageContent.json';
import content3 from './local_payload/linksPageContent.json';
import Divider from '@mui/material/Divider';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';

export default function FrontPage() {


  return (
    <Box sx={{ marginTop: 3 }}>
    <Grid container spacing={2}>
        <Grid item md={12} xs={12}>
          <Carousel autoPlay={true} animation="slide" >
            <CardMedia key={"index"} image={process.env.PUBLIC_URL + "/images/pine-dock-2.jpg"} height="250px" component="img" alt={"abc"}/>
            <CardMedia key={"index"} image={process.env.PUBLIC_URL + "/images/pine-dock-1.jpg"} height="250px" component="img" alt={"abc"}/>
            <CardMedia key={"index"} image={process.env.PUBLIC_URL + "/images/pine-dock-3.jpg"} height="250px" component="img" alt={"abc"}/>
          </Carousel>
        </Grid>
      </Grid>

      <Grid lg={12} md={12} xs={12}>
        <Card>
          <CardContent>
            <Typography gutterBottom variant="h4" component="div">Welcome to my website</Typography>
          </CardContent>
        </Card>
      </Grid>

<Divider><MoreHorizIcon/></Divider>

      <Grid container spacing={2}>
        <Grid item md={12} xs={12}>
          {content2.map(((item, index) =>
            <Accordion key={item.id}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls={item.id} id={item.id} >
                <Typography>{item.title}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>{item.description}</Typography>
              </AccordionDetails>
            </Accordion>
          ))}
        </Grid>
      </Grid>
<Divider><MoreHorizIcon/></Divider>

      <Grid container spacing={2}>
        <Grid item md={12} xs={12}>
      <List>
      {content3.map(((item, index) =>
        <ListItemButton key={index} component="a" href={item.link}>
          <ListItemText primary={item.platform} />
        </ListItemButton>
      ))}
    </List>
    </Grid>
      </Grid>

    </Box>

  );
}
