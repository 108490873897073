import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Brightness4Icon from '@mui/icons-material/Brightness4';
import Brightness7Icon from '@mui/icons-material/Brightness7';
import img from "./images/favicon.ico"
import Box from '@mui/material/Box';
import { useNavigate } from 'react-router-dom';

export default function MyAppBar({ setThemeChanger, theme, setThing, open }) {

  const navigate = useNavigate();



  return (
    <AppBar position="static">
      <Container maxWidth="lg">
        <Toolbar>
          <Box sx={{ flexGrow: { xs: 0, md: 0 }, display: { xs: 'flex' } }}>
            <IconButton onClick={() => navigate("/")} sx={{ display: { md: 'flex' }, mr: 1 }}>
              <Avatar variant="rounded" alt="logo" src={img} />
            </IconButton>
          </Box>
          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'flex' } }}>
            <Button onClick={() => navigate("projects")} color="inherit">Projects</Button>
            <Button onClick={() => navigate('setup')} color="inherit">setup</Button>
          </Box>
          <Box sx={{ flexGrow: 0 }}>

            <IconButton
              sx={{ ml: 1 }}
              color="inherit"
              onClick={() => setThemeChanger((theme === "light") ? "dark" : "light")}>
              {(theme === "light") ? <Brightness4Icon /> : <Brightness7Icon />}
            </IconButton>

            <IconButton
              color="inherit"
              aria-label="open drawer"
              sx={{ ml: 1, display: { xs: 'none', md: 'none' } }}
            >
              <MenuIcon />
            </IconButton>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  )
}
