import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { CardActionArea, CardActions, Stack } from '@mui/material';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import content from './local_payload/projectsPageContent.json';
import { useNavigate } from 'react-router-dom';
import Chip from '@mui/material/Chip';

export default function ProjectsPage() {
  const navigate = useNavigate();


  return (
    <Box sx={{ marginTop: 1 }}>
      <Grid container spacing={2}>
        {content.map(((item, index) =>
          <Grid key={item.id} item lg={4} md={6} xs={12}>

            <Card>
              <CardActionArea onClick={() => navigate(`${item.id}`)}>
                <CardMedia
                  component="img"
                  height="200"
                  image={process.env.PUBLIC_URL + item.thumbnail}
                  alt={item.title}
                />
                <CardContent>
                  <Typography gutterBottom variant="h5" component="div">
                    {item.title}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    {item.description}
                  </Typography>
                </CardContent>
              </CardActionArea>
              <CardActions disableSpacing>
                <Stack direction="row" spacing={1}>
                  {item.tags.map(((tag, index) =>
                    <Chip key={index} label={tag} component="a" clickable />
                  ))}
                </Stack>
              </CardActions>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>

  );
}
