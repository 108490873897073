import { useParams } from "react-router-dom";
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { Box, Link, Stack, Chip } from '@mui/material';
import content from './local_payload/projectsPageContent.json';
import Carousel from 'react-material-ui-carousel';
import Grid from '@mui/material/Grid';

export default function ProjectDetails() {
  const { id } = useParams();
  let record = findRecord(content, id);
  if (record == null) {
    return (<h1>No project found</h1>)
  } else {
    return (
      <Box sx={{ marginTop: 1 }}>
        <Grid container spacing={2}>
          <Grid item md={6} xs={12}>
            <Carousel autoPlay={false} animation="slide" >
              {record.img.map(((img, index) =>

                <CardMedia key={index}
                  component="img"
                  image={process.env.PUBLIC_URL + img}
                  alt={record.id + "-" + index}
                />
              ))}
            </Carousel>
          </Grid>
          <Grid item md={6} xs={12}>
            <CardContent>
              <Typography gutterBottom variant="h5" component="div">
                {record.title}
              </Typography>
              <Stack direction="row" spacing={1}>
                {record.tags.map(((tag, index) =>
                  <Chip key={index} label={tag} component="a" clickable />
                ))}
              </Stack>
              <Typography variant="body2" color="text.secondary">
                {record.description}
              </Typography>
              <Link variant="body2" href={record.longDescription}>
                {record.longDescription}
              </Link>
            </CardContent>
          </Grid>
        </Grid>
      </Box>
    )
  }
}

function findRecord(json, query) {
  let record = null;
  for (let key in json) {
    if (json[key].id === query) {
      record = json[key];
    }
  }
  return (record)
}
