import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import content from './local_payload/setupPageContent.json';
import ComputerIcon from '@mui/icons-material/Computer';
import PersonalVideoIcon from '@mui/icons-material/PersonalVideo';
import SmartphoneIcon from '@mui/icons-material/Smartphone';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Carousel from 'react-material-ui-carousel';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';

export default function SetupPage() {

  const [image, setImage] = React.useState(process.env.PUBLIC_URL + "/images/no_image.jpg");
  const [open, setOpen] = React.useState(false); ///////
  const handleClickOpen = (url) => { handleSetImage(url); setOpen(true);};
  const handleClose = () => { setOpen(false);};

  const handleSetImage= (url) => {
    setImage(url);
  };

  return (
    <Box sx={{ marginTop: 1 }}>

      <Grid container spacing={2}>
        {content.map(((item, index) =>
          <Grid key={item.id} item lg={12} md={12} xs={12}>
            <Card>
              <CardContent>
                <Typography gutterBottom variant="h4" component="div">{getTypeIcon(item.type)} {item.title}</Typography>
                <Typography variant="h6" color="text.secondary">{item.description}</Typography>
                <Typography variant="body2" color="text.secondary">{item.longDescription}</Typography>
                <Grid container>
                  <Grid lg={9} md={9} xs={12}>
                    <TableContainer component={Paper}>
                      <Table>
                        <TableBody>
                          {item.specs.map(((item2, index2) =>
                          <>
                            {(item2.Model !== undefined) ? createSpecRow("Model",item2.Model) : null}
                            {(item2.GPU !== undefined) ? createSpecRow("GPU",item2.GPU) : null}
                            {(item2.CPU !== undefined) ? createSpecRow("CPU",item2.CPU) : null}
                            {(item2.Motherboard !== undefined) ? createSpecRow("Motherboard",item2.Motherboard) : null}
                            {(item2.Cooling !== undefined) ? createSpecRow("Cooling",item2.Cooling) : null}
                            {(item2.Case !== undefined) ? createSpecRow("Case",item2.Case) : null}
                            {(item2.Memory !== undefined) ? createSpecRow("Memory",item2.Memory) : null}
                            {(item2.OS !== undefined) ? createSpecRow("OS",item2.OS) : null}
                            {(item2.PSU !== undefined) ? createSpecRow("PSU",item2.PSU) : null}
                            {(item2.SSD1 !== undefined) ? createSpecRow("SSD1",item2.SSD1) : null}
                            {(item2.SSD2 !== undefined) ? createSpecRow("SSD2",item2.SSD2) : null}
                            {(item2.Monitor1 !== undefined) ? createSpecRow("Monitor1",item2.Monitor1) : null}
                            {(item2.Monitor2 !== undefined) ? createSpecRow("Monitor2",item2.Monitor2) : null}
                            {(item2.Monitor3 !== undefined) ? createSpecRow("Monitor3",item2.Monitor3) : null}
                            {(item2.Monitor4 !== undefined) ? createSpecRow("Monitor4",item2.Monitor4) : null}
                          </>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Grid>

                  <Grid lg={3} md={3} xs={12}>
                  <Carousel autoPlay={false} renderIndicator={true} animation="slide" >
                      {item.img.map(((img, index) =>
                        <Card>
                          <CardMedia onClick={() => handleClickOpen(process.env.PUBLIC_URL + img)}
                            component="img"
                            width="100%"
                            image={process.env.PUBLIC_URL + img}
                            alt={item.id + "-" + index}
                          />
                        </Card>
                      ))}
                  </Carousel>
                </Grid>
                </Grid>
              </CardContent>



            </Card>
          </Grid>








        ))}
      </Grid>


      <Dialog open={open} onClose={handleClose} PaperComponent={Box}>
        <DialogContent>
          <Card>
            <CardMedia component="img" width="100%" image={image} alt={image}/>
          </Card>
        </DialogContent>
      </Dialog>



    </Box>
  );





}

function createSpecRow(category,value){
  return <TableRow hover><TableCell align="left">{category}</TableCell><TableCell align="left">{value}</TableCell></TableRow>
}


function getTypeIcon(type){
  switch(type){
    case "laptop": return <ComputerIcon/>;
    case "desktop" : return <PersonalVideoIcon/>;
    case "phone": return <SmartphoneIcon/>;
    default: return null;
  }

}


