import * as React from 'react';
import { useState } from 'react';
import Container from '@mui/material/Container';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import MyAppBar from './MyAppBar';
import { Outlet } from 'react-router-dom';


const darkTheme = createTheme({
  palette: {
    mode: 'dark',
  },
});
const lightTheme = createTheme({
  palette: {
    mode: 'light',
  },
});


export default function FrontPage() {

  let storedTheme = localStorage.getItem("theme");

  const [theme, setTheme] = useState((storedTheme === null) ? "dark" : storedTheme);

  function updateTheme(theme) {
    setTheme(theme);
    localStorage.setItem("theme", theme)
  }

  return (

    <ThemeProvider theme={(theme === "dark" ? darkTheme : lightTheme)}>
      <CssBaseline />
      <MyAppBar setThemeChanger={updateTheme} theme={theme} />
      <Container maxWidth="lg">

        <Outlet />

      </Container>
    </ThemeProvider>

  );
}
