import './App.css';
import FrontPage from './FrontPage';
import ProjectsPage from './ProjectsPage';
import AboutPage from './AboutPage';
import MainPage from './MainPage';
import LinksPage from './LinksPage';
import SetupPage from './SetupPage';
import ProjectDetails from './ProjectDetails';
import { Route, Routes } from 'react-router-dom';

function App() {
  return (
    <Routes>
      <Route path="/" element={<FrontPage />}>
        <Route index element={<MainPage />} />
        <Route path="projects">
          <Route index element={<ProjectsPage />} />
          <Route path=":id" element={<ProjectDetails />} />
        </Route>
        <Route path="/about" element={<AboutPage />} />
        <Route path="/links" element={<LinksPage />} />
        <Route path="/setup" element={<SetupPage />} />
        <Route path="/*" element={<h1>No page found</h1>} />
      </Route>
    </Routes>
  );
}

export default App;
