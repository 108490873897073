import * as React from 'react';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import content from './local_payload/linksPageContent.json';
export default function LinksPage() {

  return (
    <List>
      {content.map(((item, index) =>
        <ListItemButton key={index} component="a" href={item.link}>
          <ListItemText primary={item.platform} />
        </ListItemButton>
      ))}
    </List>
  );
}
